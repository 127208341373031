/* eslint-disable */
const SET_LANGUAGES = "SET_LANGUAGES";
const SET_TRANSLATIONS = "SET_TRANSLATIONS";
// const GET_LANGUAGES_REQUEST = "GET_LANGUAGES_REQUEST";
// const GET_LANGUAGES_REQUEST_SUCCESS = "GET_LANGUAGES_REQUEST_SUCCESS";
// const GET_LANGUAGES_REQUEST_FAIL = "GET_LANGUAGES_REQUEST_FAIL";
const GET_WIDGET_LANGUAGES_REQUEST = "GET_WIDGET_LANGUAGES_REQUEST";
const GET_WIDGET_LANGUAGES_REQUEST_SUCCESS =
  "GET_WIDGET_LANGUAGES_REQUEST_SUCCESS";
const GET_WIDGET_LANGUAGES_REQUEST_FAIL = "GET_WIDGET_LANGUAGES_REQUEST_FAIL";
const GET_WIDGET_TRANSLATION_REQUEST = "GET_WIDGET_TRANSLATION_REQUEST";
const GET_WIDGET_TRANSLATION_REQUEST_SUCCESS =
  "GET_WIDGET_TRANSLATION_REQUEST_SUCCESS";
const GET_WIDGET_TRANSLATION_REQUEST_FAIL =
  "GET_WIDGET_TRANSLATION_REQUEST_FAIL";
const SAVE_WIDGET_TRANSLATIONS_REQUEST = "SAVE_WIDGET_TRANSLATIONS_REQUEST";
const SAVE_WIDGET_TRANSLATIONS_REQUEST_SUCCESS =
  "SAVE_WIDGET_TRANSLATIONS_REQUEST_SUCCESS";
const SAVE_WIDGET_TRANSLATIONS_REQUEST_FAIL =
  "SAVE_WIDGET_TRANSLATIONS_REQUEST_FAIL";

const SET_TRANSLATION_TREE = "SET_TRANSLATION_TREE";
const CLEAR_TRANSLATION_TREE = "CLEAR_TRANSLATION_TREE";
const SET_TRANSLATION_TREE_SELECTED_ITEM = "SET_TRANSLATION_TREE_SELECTED_ITEM";
const CLEAR_TRANSLATION_TREE_SELECTED_ITEM =
  "CLEAR_TRANSLATION_TREE_SELECTED_ITEM";
const UPDATE_TRANSLATION_DATA = "UPDATE_TRANSLATION_DATA";
const TRANSLATION_WIDGET_LIST_REQUEST = "TRANSLATION_WIDGET_LIST_REQUEST";
const TRANSLATION_WIDGET_LIST_REQUEST_SUCCESS =
  "TRANSLATION_WIDGET_LIST_REQUEST_SUCCESS";
const TRANSLATION_WIDGET_LIST_REQUEST_FAIL =
  "TRANSLATION_WIDGET_LIST_REQUEST_FAIL";
const CLEAR_TRANSLATION_WIDGET_LIST = "CLEAR_TRANSLATION_WIDGET_LIST";
const CLEAR_TRANSLATION_WIDGET = "CLEAR_TRANSLATION_WIDGET";
const SET_TRANSLATION_WIDGET = "SET_TRANSLATION_WIDGET";

const CLEAR_TRANSLATION_DATA = "CLEAR_TRANSLATION_DATA";

const PROMOTE_TRANSLATION_REQUEST = "PROMOTE_TRANSLATION_REQUEST";
const PROMOTE_TRANSLATION_REQUEST_SUCCESS =
  "PROMOTE_TRANSLATION_REQUEST_SUCCESS";
const PROMOTE_TRANSLATION_REQUEST_FAIL = "PROMOTE_TRANSLATION_REQUEST_FAIL";

const fetchTranslationWidgetList = payload => ({
  type: TRANSLATION_WIDGET_LIST_REQUEST,
  payload,
});

const setTranslationWidget = payload => ({
  type: SET_TRANSLATION_WIDGET,
  payload,
});

const clearTranslationWidget = () => ({
  type: CLEAR_TRANSLATION_WIDGET,
});

const clearTranslationWidgetList = () => ({
  type: CLEAR_TRANSLATION_WIDGET_LIST,
});

// const fetchLanguages = () => ({
//   type: GET_LANGUAGES_REQUEST,
// });

const fetchWidgetLanguages = params => ({
  type: GET_WIDGET_LANGUAGES_REQUEST,
  payload: params,
});

const fetchWidgetTranslation = params => ({
  type: GET_WIDGET_TRANSLATION_REQUEST,
  payload: params,
});

const saveWidgetTranslations = params => ({
  type: SAVE_WIDGET_TRANSLATIONS_REQUEST,
  payload: params,
});

const updateTranslationData = data => ({
  type: UPDATE_TRANSLATION_DATA,
  payload: data,
});
const setTranslationTree = payload => ({
  type: SET_TRANSLATION_TREE,
  payload,
});

const clearTranslationTree = () => ({
  type: CLEAR_TRANSLATION_TREE,
});

const setSelectedTreeItem = payload => ({
  type: SET_TRANSLATION_TREE_SELECTED_ITEM,
  payload,
});

const clearSelectedTreeItem = () => ({
  type: CLEAR_TRANSLATION_TREE_SELECTED_ITEM,
});

const clearTranslationData = () => ({
  type: CLEAR_TRANSLATION_DATA,
});

const promoteTranslation = payload => ({
  type: PROMOTE_TRANSLATION_REQUEST,
  payload,
});

export {
  TRANSLATION_WIDGET_LIST_REQUEST,
  TRANSLATION_WIDGET_LIST_REQUEST_SUCCESS,
  TRANSLATION_WIDGET_LIST_REQUEST_FAIL,
  CLEAR_TRANSLATION_WIDGET_LIST,
  CLEAR_TRANSLATION_WIDGET,
  SET_TRANSLATION_WIDGET,
  fetchTranslationWidgetList,
  setTranslationWidget,
  clearTranslationWidget,
  clearTranslationWidgetList,
  SET_LANGUAGES,
  SET_TRANSLATIONS,
  // GET_LANGUAGES_REQUEST,
  // GET_LANGUAGES_REQUEST_SUCCESS,
  // GET_LANGUAGES_REQUEST_FAIL,
  GET_WIDGET_LANGUAGES_REQUEST,
  GET_WIDGET_LANGUAGES_REQUEST_SUCCESS,
  GET_WIDGET_LANGUAGES_REQUEST_FAIL,
  SAVE_WIDGET_TRANSLATIONS_REQUEST,
  SAVE_WIDGET_TRANSLATIONS_REQUEST_SUCCESS,
  SAVE_WIDGET_TRANSLATIONS_REQUEST_FAIL,
  UPDATE_TRANSLATION_DATA,
  SET_TRANSLATION_TREE_SELECTED_ITEM,
  CLEAR_TRANSLATION_DATA,
  PROMOTE_TRANSLATION_REQUEST,
  PROMOTE_TRANSLATION_REQUEST_SUCCESS,
  PROMOTE_TRANSLATION_REQUEST_FAIL,
  // fetchLanguages,
  fetchWidgetLanguages,
  fetchWidgetTranslation,
  saveWidgetTranslations,
  updateTranslationData,
  setSelectedTreeItem,
  clearTranslationData,
  promoteTranslation,
};
