/* eslint-disable no-unused-vars */
import {
  TRANSLATION_WIDGET_LIST_REQUEST_SUCCESS,
  TRANSLATION_WIDGET_LIST_REQUEST_FAIL,
  CLEAR_TRANSLATION_WIDGET_LIST,
  CLEAR_TRANSLATION_WIDGET,
  SET_TRANSLATION_WIDGET,
  // GET_LANGUAGES_REQUEST_SUCCESS,
  // GET_LANGUAGES_REQUEST_FAIL,
  GET_WIDGET_LANGUAGES_REQUEST_SUCCESS,
  GET_WIDGET_LANGUAGES_REQUEST_FAIL,
  SAVE_WIDGET_TRANSLATIONS_REQUEST_SUCCESS,
  SAVE_WIDGET_TRANSLATIONS_REQUEST_FAIL,
  UPDATE_TRANSLATION_DATA,
  SET_TRANSLATION_TREE_SELECTED_ITEM,
  CLEAR_TRANSLATION_DATA,
  TRANSLATION_WIDGET_LIST_REQUEST,
} from "actions/translationsActions";

const initialState = {
  current: "",
  list: [],
  selectedWidgetVersions: null,
  translationsChanged: false,
  translations: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case SET_TRANSLATION_WIDGET:
      return {
        ...state,
        current: payload,
      };
    case CLEAR_TRANSLATION_WIDGET:
      return {
        ...state,
        current: "",
      };
    case CLEAR_TRANSLATION_WIDGET_LIST:
      return {
        ...state,
        list: [],
      };
    case TRANSLATION_WIDGET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TRANSLATION_WIDGET_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        list: payload,
        loading: false,
      };
    case TRANSLATION_WIDGET_LIST_REQUEST_FAIL:
      return {
        ...state,
        ...error,
        loading: false,
      };
    // case GET_LANGUAGES_REQUEST_SUCCESS:
    //   return {
    //     ...state,
    //     locales: payload.languages,
    //   };
    // case GET_LANGUAGES_REQUEST_FAIL:
    //   return {
    //     ...state,
    //     error: payload.languages,
    //   };
    case GET_WIDGET_LANGUAGES_REQUEST_SUCCESS:
      return {
        ...state,
        translations: payload,
      };
    case GET_WIDGET_LANGUAGES_REQUEST_FAIL:
      return {
        ...state,
        error: payload,
      };
    case SAVE_WIDGET_TRANSLATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        translations_result: payload,
      };
    case SAVE_WIDGET_TRANSLATIONS_REQUEST_FAIL:
      return {
        ...state,
        error: payload,
      };
    case UPDATE_TRANSLATION_DATA:
      return {
        ...state,
        translations: payload,
        translationsChanged: true,
      };
    case SET_TRANSLATION_TREE_SELECTED_ITEM:
      return {
        ...state,
        selectedTreeItem: payload,
      };
    case CLEAR_TRANSLATION_DATA:
      return {
        ...state,
        translationsChanged: false,
        translations: null,
      };
    default:
      return state;
  }
};
